// const mobileBreakpoint = 820;
// let isMobile = window.innerWidth <= mobileBreakpoint;

window.addEventListener('load', function () {

  let datepicker_from = document.getElementById('hin_date');
  let datepicker_to = document.getElementById('rueck_date');
  let menu_items = document.querySelectorAll('.has-children a');


  if(datepicker_from) {
    datepicker_from.addEventListener('change', function() {

      let currentDate = convertDate(this.value);
      currentDate.setDate(currentDate.getDate() + 7);
      datepicker_to.value = formatDate(currentDate);
    });
  }

  function convertDate(date) {
    date = date.split('.');
    return new Date(date[1]+"/"+date[0]+"/"+date[2]);
  }

  function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [day, month, year].join('.');
  }

  function checkScrollState() {
    let bodyElement = document.getElementsByTagName('body')[0];
    if (window.scrollY < 150) {
      bodyElement.classList.remove('scrolled');
    } else {
      bodyElement.classList.add('scrolled');
    }
  }

  window.onscroll = function () {
    checkScrollState()
  };
  checkScrollState();

  for(let i = 0; i < menu_items.length; i++) {
    menu_items[i].addEventListener('click', function(e){
      e.preventDefault();
    });
  }


});